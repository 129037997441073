import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { isType } from '../utils/typecheck'
import Layout from '../components/layout'
import Markdown from '../utils/markdown'
import Contact from '../components/sections/contact'
// import SEO from '../components/seo'

export const query = graphql`
    query GetContact {
        allAirtable(
            filter: { table: { eq: "Kontakt" } }
            sort: { order: ASC, fields: rowIndex }
        ) {
            nodes {
                data {
                    Type
                    Content
                    Attachments {
                        src
                        srcSet
                        sizes
                    }
                }
            }
        }
    }
`

export default function ContactPage({ data }) {
    const items = data.allAirtable.nodes

    let introductionElements = []

    let kickerCounter = -1
    items.map((item) => {
        if (isType(item.data.Type, 'Kolumne')) kickerCounter++

        if (kickerCounter === 0) {
            introductionElements.push(item)
        }
    })

    const introductionSection = introductionElements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'H1'):
                return <h1 key={index}>{formattedContent}</h1>

            case isType(item.data.Type, 'Kolumne'):
                return (
                    <span key={index} className="kicker">
                        {formattedContent}
                    </span>
                )

            case isType(item.data.Type, 'Bild'):
                return (
                    <figure key={index} className="single-image">
                        {!!item.data.Attachments && (
                            <img
                                alt=""
                                srcSet={item.data.Attachments[0].srcSet}
                                sizes={item.data.Attachments[0].sizes}
                            />
                        )}
                        <figcaption>{formattedContent}</figcaption>
                    </figure>
                )

            case isType(item.data.Type, 'HTML'):
                return (
                    <div key={index} className="contact-link">
                        {formattedContent}
                    </div>
                )
        }
    })

    return (
        <ContentContainer>
            <Layout>
                <div className="introduction-section">
                    {introductionSection}
                </div>

                <Contact />
            </Layout>
        </ContentContainer>
    )
}

const ContentContainer = styled.div`
    .introduction-section {
        display: grid;
        grid-template-columns: repeat(25, 1fr);

        .contact-link {
            font-size: 1.222rem;
            font-family: 'SawtonBauhaus';
            grid-column: 3 / span 18;
            margin-bottom: 2rem;

            @media (min-width: ${(props) => props.theme.md}) {
                grid-column: 5 / span 18;
            }

            @media (min-width: ${(props) => props.theme.lg}) {
                margin-top: 3rem;

                &:nth-of-type(1) {
                    grid-column: 6 / span 5;
                }

                &:nth-of-type(2) {
                    grid-column: 12 / span 5;
                }

                &:nth-of-type(3) {
                    grid-column: 18 / span 5;
                }
            }
        }

        .single-image {
            margin-bottom: 0;
        }
    }
`
